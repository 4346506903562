import MoniepointLogo from '@/ui/svgs/moniepoint-logo';

import { Link } from '@/lib/navigation';

export function HeaderLogo({ className }: { className?: string }) {
  return (
    <Link href="/" aria-label="Moniepoint Home" className={className}>
      <MoniepointLogo />
    </Link>
  );
}
